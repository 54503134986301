<template>
  <el-container>
    <el-container>
      <!-- 中间内容 -->
      <el-main>
        <h2 class="depTitle">参会人员管理</h2>
        <div class="RightDiv">
          <div class="firDiv">
            <el-select v-model="attTypeVal" clearable placeholder="请选择参会人员类型" @change="typeChange(attTypeVal)">
              <el-option v-for="item in participateTypeOptions" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="firDiv">
            <el-select v-model="attStaVal" clearable placeholder="会议邀请发送状态" @change="statChange(attStaVal)">
              <el-option v-for="item in attStatuOptions" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="firDiv">
            <el-select v-model="attConVal" clearable placeholder="请选择是否参会" @change="attconfChange(attConVal)">
              <el-option v-for="item in isConferenceOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="firDiv">
            <el-select v-model="carTypeVal" clearable placeholder="车辆信息维护状态" @change="carTypeChange(carTypeVal)">
              <el-option v-for="item in carTypeOptions" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="firDiv">
            <el-select v-model="stayTypeVal" clearable placeholder="住宿信息维护状态" @change="stayTypeChange(stayTypeVal)">
              <el-option v-for="item in stayTypeOptions" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="RightDiv">
          <div class="firDiv">
            <el-input v-model="attenName" clearable placeholder="请输入姓名" @keyup.enter.native="searchName" @blur="searchName" @clear="searchName"></el-input>
          </div>
          <div class="firDiv">
            <el-input v-model="attenPhone" clearable placeholder="请输入手机号" @keyup.enter.native="searchName" @blur="searchName" @clear="searchName"></el-input>
          </div>
          <div class="firDiv">
            <el-select v-model="activityStaVal" clearable placeholder="活动邀请发送状态" @change="activityStaChange(activityStaVal)">
              <el-option v-for="item in attStatuOptions" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="firDiv">
            <el-select v-model="activityConVal" clearable placeholder="是否参加活动" @change="activityConChange(activityConVal)">
              <el-option v-for="item in isConferenceOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="firDiv">
            <el-select v-model="dockTypeVal" clearable placeholder="对接人信息维护状态" @change="dockTypeChange(dockTypeVal)">
              <el-option v-for="item in dockTypeOptions" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="RightDiv">
          <div class="firDiv">
            <el-button type="success" v-if="sendNewsCilck" :loading="sendNewsCilckTwo" @click="sendNews" :disabled="haveAuth"><i>发送新闻快讯</i></el-button>
          </div>
          <div class="firDiv">
            <el-button type="success" v-if="sendActivityCilck" :loading="sendActivityCilckTwo" @click="sendActivity" :disabled="haveAuth"><i>发送活动邀请</i></el-button>
          </div>
          <div class="firDiv">
            <el-button type="success" v-if="sendMessageCilck" :loading="sendMessageCilckTwo" @click="sendMessage" :disabled="haveAuth"><i>发送会议邀请</i></el-button>
          </div>
          <div class="firDiv">
            <el-button type="warning" v-if="exportTemplateCilck" :loading="exportTemplateCilckTwo" @click="exportTemplate" :disabled="haveAuth"><i class="el-icon-download">导出模板</i></el-button>
          </div>
          <div class="firDiv">
            <el-upload
                ref="upload"
                class="upload-demo"
                action
                :auto-upload="false"
                accept=".xlsx"
                :limit=1
                :on-change="handleChange"
                :on-error="handleError"
                :file-list="file"
            >
              <el-button icon="el-icon-upload" type="primary" :disabled="haveAuth">导入参会人员信息</el-button>
            </el-upload>
          </div>
          <div class="firDiv">
            <el-button type="warning" v-if="exportCilck" :loading="exportCilckTwo" @click="exportAttenDetail" :disabled="haveAuth"><i class="el-icon-download">导出参会人员信息</i></el-button>
          </div>
          <div class="firDiv">
            <el-button type="primary" @click="depaddKeshi" :disabled="haveAuth">添加参会人员</el-button>
          </div>
        </div>

        <el-table
            ref="multipleTable"
            :data="tableData" border center
            tooltip-effect="dark"
            :row-key="row=>row.id"
            style="width: 100%"
            @selection-change="handleSelectionChange"
        >
          <el-table-column
              type="selection"
              :reserve-selection="true"
              width="55"  align="center">
          </el-table-column>
          <el-table-column type="index" label="序号" width="55" align="center">
          </el-table-column>
          <el-table-column prop="name" label="姓名" align="center" width="100">
          </el-table-column>
          <el-table-column prop="workUnit" label="单位" align="center" width="250">
          </el-table-column>
          <el-table-column prop="duties" label="职务" align="center" width="100">
          </el-table-column>
          <el-table-column prop="phone" label="手机号" align="center" width="150">
          </el-table-column>
          <el-table-column prop="participateName" label="参会人类型" align="center" width="100">
          </el-table-column>

          <el-table-column prop="status" label="参会邀请发送状态" align="center" width="140">
            <template slot-scope="scope">
              <div v-if="scope.row.status=='0'">未发送</div>
              <div v-else-if="scope.row.status=='1'">已发送</div>
            </template>
          </el-table-column>
          <el-table-column prop="isConference" label="是否确认参会" align="center" width="120">
            <template slot-scope="scope">
              <div v-if="scope.row.isConference=='0'">待确认</div>
              <div v-else-if="scope.row.isConference=='1'">确认</div>
              <div v-else-if="scope.row.isConference=='2'">拒绝</div>
            </template>
          </el-table-column>

          <el-table-column prop="sendStatus" label="活动邀请发送状态" align="center" width="140">
            <template slot-scope="scope">
              <div v-if="scope.row.sendStatus=='0'">未发送</div>
              <div v-else-if="scope.row.sendStatus=='1'">已发送</div>
            </template>
          </el-table-column>
          <el-table-column prop="activityStatus" label="是否参加活动" align="center" width="120">
            <template slot-scope="scope">
              <div v-if="scope.row.activityStatus=='0'">待确认</div>
              <div v-else-if="scope.row.activityStatus=='1'">确认</div>
              <div v-else-if="scope.row.activityStatus=='2'">拒绝</div>
            </template>
          </el-table-column>

          <el-table-column prop="iCar" label="车辆信息" align="center">
          </el-table-column>

          <el-table-column prop="iAccommodation" label="住宿信息" align="center">
          </el-table-column>

          <el-table-column prop="dockingPersonName" label="对接人" align="center" width="100">
          </el-table-column>

          <el-table-column prop="iDock" label="对接人维护状态" align="center" width="130">
          </el-table-column>

          <el-table-column prop="remark" label="备注" align="center" width="150" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="操作" align="center" width="280">
            <template style="display: flex" slot-scope="scope">
              <el-button size="mini" type="text" :disabled="haveAuth" @click="editManaMeet(scope.row)"><i>编辑</i></el-button>
              <el-button size="mini" type="text" :disabled="haveAuth" @click="delManMeet(scope.row)"><i>删除</i></el-button>
              <el-button size="mini" type="text" :disabled="haveAuth" @click="editCar(scope.row)">维护车辆信息<i></i></el-button>
              <el-button size="mini" type="text" :disabled="haveAuth" @click="editStay(scope.row)">维护住宿信息<i></i></el-button>
              <el-button size="mini" type="text" :disabled="haveAuth" @click="editDock(scope.row)">维护对接人信息<i></i></el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
        </el-pagination>
      </el-main>
    </el-container>
    <el-dialog :title="addName" :visible.sync="AdddialogVisible" width="430px" top="1vh" :before-close="AdddialogVisibleClose">
      <el-form :model="AddDateModel" ref="AddDateRef" :rules="AddDateRules" label-width="130px">

        <el-form-item label="对接人：" prop="dockingPerson">
          <el-select v-model="AddDateModel.dockingPerson" placeholder="请选择对接人">
            <el-option v-for="item in dockingOptions" :key="item.dockingPersonId" :label="item.dockingPersonName" :value="item.dockingPersonId">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="参会人姓名：" prop="name">
          <el-input v-model="AddDateModel.name" placeholder="请填写参会人姓名"></el-input>
        </el-form-item>

        <el-form-item label="手机号：" prop="phone">
          <el-input v-model="AddDateModel.phone" placeholder="请填写手机号"></el-input>
        </el-form-item>

        <el-form-item label="单位：" prop="workUnit">
          <el-input v-model="AddDateModel.workUnit" placeholder="请填写单位"></el-input>
        </el-form-item>

        <el-form-item label="职务：" prop="duties">
          <el-input v-model="AddDateModel.duties" placeholder="请填写职务"></el-input>
        </el-form-item>

        <el-form-item label="身份证号：" prop="idCard">
          <el-input v-model="AddDateModel.idCard" placeholder="请填写身份证号"></el-input>
        </el-form-item>

        <el-form-item label="性别：" prop="sex">
          <el-radio-group v-model="AddDateModel.sex">
            <el-radio label="0">男</el-radio>
            <el-radio label="1">女</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="邮箱：" prop="email">
          <el-input v-model="AddDateModel.email" placeholder="请填写邮箱"></el-input>
        </el-form-item>

        <el-form-item label="参会人类型：" prop="participateType">
          <el-select v-model="AddDateModel.participateType" placeholder="请选择参会人类型">
            <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="是否确认参会：" prop="isConference">
          <el-select v-model="AddDateModel.isConference" placeholder="请选择参会人类型">
            <el-option v-for="item in isConferenceOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="备注：" prop="remark">
          <el-input type="textarea" v-model="AddDateModel.remark" placeholder="请填写备注"></el-input>
        </el-form-item>

        <el-form-item class="depar-dia btnCen">
          <el-button v-if="addDepClick" type="primary" :loading="addDepClickKing" @click="parmen">{{btnText}}</el-button>
          <el-button v-else type="info" :loading="addDepClickKing"></el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--住宿信息-->
    <el-dialog :title="addStay" :visible.sync="AddStayVisible" width="430px" top="1vh" :before-close="AddStayVisibleClose">
      <el-form :model="AddStayModel" ref="AddStayRef" :rules="AddStayRules" label-width="130px">
        <el-form-item label="是否需要安排住宿：" prop="iAccommodation" label-width="150px">
          <el-radio-group v-model="AddStayModel.iAccommodation" @change="changeStay(AddStayModel.iAccommodation)">
            <el-radio label="0">否</el-radio>
            <el-radio label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="入住日期：" prop="checkInTime" v-if="stayShow" label-width="150px">
          <el-date-picker v-model="AddStayModel.checkInTime"
                          value-format="yyyy-MM-dd" type="date" placeholder="请选择入住日期">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="退房日期：" prop="checkOutTime" v-if="stayShow" label-width="150px">
          <el-date-picker v-model="AddStayModel.checkOutTime"
                          value-format="yyyy-MM-dd" type="date" placeholder="请选择退房日期">
          </el-date-picker>
        </el-form-item>


        <el-form-item class="depar-dia btnCen">
          <el-button v-if="addStayClick" type="primary" :loading="addStayClickKing" @click="addStayParmen">{{btnSubText}}</el-button>
          <el-button v-else type="info" :loading="addStayClickKing"></el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--车辆信息-->
    <el-dialog :title="addCar" :visible.sync="AddCarVisible" width="500px" top="1vh" :before-close="AddCarVisibleClose">
      <el-form :model="AddCarModel" ref="AddCarRef" :rules="AddCarRules" label-width="130px">
        <el-form-item label="交通方式：" prop="iCar" label-width="140px">
          <el-radio-group v-model="AddCarModel.iCar" @change="changeCar(AddCarModel.iCar)">
            <el-radio label="0">不需要</el-radio>
            <el-radio label="1">飞机</el-radio>
            <el-radio label="2">火车</el-radio>
            <el-radio label="3">自驾</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="来程班次及城市：" prop="incomingInformation" label-width="140px" v-if="carShow">
          <el-input v-model="AddCarModel.incomingInformation" placeholder="请填写来程班次及城市"></el-input>
        </el-form-item>
        <el-form-item label="来程出发时间：" prop="trafficDepartureTime" label-width="140px" v-if="carShow">
          <el-date-picker v-model="AddCarModel.trafficDepartureTime"
                          value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="请选择来程出发时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="来程抵达时间：" prop="trafficArrivalTime" label-width="140px" v-if="carShow">
          <el-date-picker v-model="AddCarModel.trafficArrivalTime"
                          value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="请选择来程抵达时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="来程出票状态：" prop="comeTicket" label-width="140px" v-if="carShow">
          <el-radio-group v-model="AddCarModel.comeTicket">
            <el-radio label="0">未出票</el-radio>
            <el-radio label="1">已出票</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="回程班次及城市：" prop="returnInformation" label-width="140px" v-if="carShow">
          <el-input v-model="AddCarModel.returnInformation" placeholder="请填写回程班次及城市"></el-input>
        </el-form-item>
        <el-form-item label="回程出发时间：" prop="returnDepartureTime" label-width="140px" v-if="carShow">
          <el-date-picker v-model="AddCarModel.returnDepartureTime"
                          value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="请选择回程出发时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="回程抵达时间：" prop="returnArrivalTime" label-width="140px" v-if="carShow">
          <el-date-picker v-model="AddCarModel.returnArrivalTime"
                          value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="请选择回程抵达时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="回程出票状态：" prop="returnTicket" label-width="140px" v-if="carShow">
          <el-radio-group v-model="AddCarModel.returnTicket">
            <el-radio label="0">未出票</el-radio>
            <el-radio label="1">已出票</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="车牌号：" prop="carNumber" label-width="140px" v-if="carNumShow">
          <el-input v-model="AddCarModel.carNumber" placeholder="请填写车牌号"></el-input>
        </el-form-item>


        <el-form-item class="depar-dia btnCen">
          <el-button v-if="addCarClick" type="primary" :loading="addCarClickKing" @click="addCarParmen">{{btnSubText}}</el-button>
          <el-button v-else type="info" :loading="addCarClickKing"></el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--对接人信息-->
    <el-dialog :title="addDock" :visible.sync="AddDockVisible" width="430px" top="1vh" :before-close="AddDockVisibleClose">
      <el-form :model="AddDockModel" ref="AddDockRef" label-width="130px">

        <el-form-item label="入住日期：" prop="dockingPersonIn" label-width="150px">
          <el-date-picker v-model="AddDockModel.dockingPersonIn"
                          value-format="yyyy-MM-dd" type="date" placeholder="请选择入住日期">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="退房日期：" prop="dockingPersonOut" label-width="150px">
          <el-date-picker v-model="AddDockModel.dockingPersonOut"
                          value-format="yyyy-MM-dd" type="date" placeholder="请选择退房日期">
          </el-date-picker>
        </el-form-item>


        <el-form-item class="depar-dia btnCen">
          <el-button v-if="addDockClick" type="primary" :loading="addDockClickKing" @click="addDockParmen">{{btnSubText}}</el-button>
          <el-button v-else type="info" :loading="addDockClickKing"></el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-container>
</template>

<script>
import _qs from "qs";
export default {
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value && String(value) !== "0") {
        return callback(new Error("不能为空"));
      }
      setTimeout(() => {
        if (!/^1[3|4|5|7|8][0-9]\d{8}$/.test(value)) {
          callback(new Error("请输入正确的手机号"));
        } else {
          callback();
        }
      }, 100);
    };
    return {
      multipleSelection: [],
      file:[],
      value: true,
      novalue: false,
      btnText: "添加",
      btnSubText: "提交",
      addName: "添加参会人员",
      addStay: "维护住宿信息",
      addCar: "维护车辆信息",
      addDock: "维护对接人住宿信息",
      reayName: "",
      addDepClick: true,
      addDepClickKing: false,
      addStayClick: true,
      addStayClickKing: false,
      addCarClick: true,
      addCarClickKing: false,
      addDockClick: true,
      addDockClickKing: false,
      AddDateModel: {
        name: '',
        phone:'',
        workUnit:'',
        duties:'',
        idCard:'',
        sex:'',
        email:'',
        isConference:'',
        participateType:'',
        dockingPerson:'',
        remark:''
      },
      AddCarModel:{
        iCar:'',
        incomingInformation:'',
        trafficDepartureTime:'',
        trafficArrivalTime:'',
        returnInformation:'',
        returnDepartureTime:'',
        returnArrivalTime:'',
        returnTicket:'',
        comeTicket:'',
        carNumber:''
      },
      AddStayModel:{
        iAccommodation: '',
        checkInTime:'',
        checkOutTime:''
      },
      AddDockModel:{
        dockingPersonIn: '',
        dockingPersonOut:''
      },
      typeOptions: [
        {
          value: "0",
          label: "参会人员",
        },
        {
          value: "1",
          label: "普通嘉宾",
        },
        {
          value: "2",
          label: "演讲嘉宾",
        },
        {
          value: "3",
          label: "主持嘉宾",
        },
        {
          value: "4",
          label: "点评嘉宾",
        },
        {
          value: "5",
          label: "领奖嘉宾",
        }
      ],
      isConferenceOptions: [
        {
          value: "0",
          label: "待确认",
        },
        {
          value: "1",
          label: "确认",
        },
        {
          value: "2",
          label: "拒绝",
        }
      ],
      AddDateRules: {
        name: [
          { required: true, message: "请填写参会人姓名", trigger: "blur" },
        ],
        phone: [
          { required: true, validator: checkAge, trigger: "blur" },
        ],
        workUnit: [
          { required: true, message: "请填写单位", trigger: "blur" },
        ],
        duties: [
          { required: true, message: "请填写职务", trigger: "blur" },
        ],
        participateType: [
          { required: true, message: "请选择参会人类型", trigger: "blur" },
        ],
        dockingPerson: [
          { required: true, message: "请选择对接人", trigger: "blur" },
        ],
        sex: [
          { required: true, message: "请选择性别", trigger: "change" },
        ],
        email: [
          { required: false},
        ],
        remark: [
          { required: false},
        ]
      },
      AddStayRules:{
        iAccommodation: [
          { required: true, message: "请选择是否需要住宿", trigger: "blur" },
        ]
      },
      AddCarRules:{
        iCar: [
          { required: true, message: "请选择交通方式", trigger: "blur" },
        ]
      },
      AdddialogVisible: false,
      AddStayVisible: false,
      AddCarVisible: false,
      AddDockVisible: false,
      // 当前页数
      pageNum: 1,
      // 每页显示条数
      pageSize: 10,
      // 总数
      total: 1,
      // 表格数据
      tableData: [],
      perId:'',
      attenName:"",
      attenPhone:"",
      attStatuOptions:[{name:'未发送',id:0},{name:'已发送',id:1}],
      attconferOptions:[{name:'待确认',id:0},{name:'确认',id:1}],
      participateTypeOptions:[],
      carTypeOptions:[{name:'未维护',id:0},{name:'已维护',id:1}],
      stayTypeOptions:[{name:'未维护',id:0},{name:'已维护',id:1}],
      dockTypeOptions:[{name:'未维护',id:0},{name:'已维护',id:1}],
      attStaVal:'',
      attConVal:'',

      activityStaVal:'',
      activityConVal:'',

      attTypeVal:'',
      haveAuth:false,
      addAuth:false,
      dockingOptions:[],
      stayShow:false,
      carShow:false,
      carNumShow :false,
      carTypeVal:'',
      stayTypeVal:'',
      dockTypeVal:'',
      detailId:'',
      exportCilck:true,
      exportCilckTwo:false,

      sendMessageCilck:true,
      sendMessageCilckTwo:false,

      sendActivityCilck:true,
      sendActivityCilckTwo:false,

      sendNewsCilck:true,
      sendNewsCilckTwo:false,

      exportTemplateCilck:true,
      exportTemplateCilckTwo:false,

      importCilck:true,
      importCilckTwo:false,

      messageStatusList:[],
      sendStatusList:[]
    };
  },
  created() {
    this.pageList();
    this.attendRoleList();
  },
  methods: {
    async attendRoleList() {
      let {data: res} = await this.$axios.attendRoleListAll();
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.participateTypeOptions = res.data;
      }
    },
    handleSelectionChange(val) {
      if(val.length>0){
        this.multipleSelection=[];
        this.messageStatusList=[];
        this.sendStatusList=[];
        for(let i=0;i<val.length;i++){
          this.multipleSelection.push(val[i].id);
          this.messageStatusList.push(val[i].status);
          this.sendStatusList.push(val[i].sendStatus);
        }
      }else{
        this.multipleSelection = [];
        this.messageStatusList=[];
        this.sendStatusList=[];
      }
    },
    changeStay(val){
      if(val=='1'){
        this.stayShow=true
      }else{
        this.stayShow=false
      }
    },
    changeCar(val){
      if(val=='0'){
        this.carShow=false;
        this.carNumShow = false;
      }
      else if (val == '3') {
        this.carNumShow = true;
        this.carShow=false;
      }
      else{
        this.carShow=true;
        this.carNumShow = false;
      }
    },
    searchName(){
      this.pageNum = 1;
      this.pageList();
    },
     statChange(val){
       this.pageNum = 1;
       this.attStaVal=val
       this.pageList();
     } ,
     attconfChange(val){
       this.pageNum = 1;
       this.attConVal=val
       this.pageList();
     },
    typeChange(val){
      this.pageNum = 1;
       this.attTypeVal=val
       this.pageList();
     },
    carTypeChange(val){
      this.pageNum = 1;
      this.carTypeVal=val
      this.pageList();
    },
    activityStaChange(val){
      this.pageNum = 1;
      this.activityStaVal=val
      this.pageList();
    },
    activityConChange(val){
      this.pageNum = 1;
      this.activityConVal=val
      this.pageList();
    },
    stayTypeChange(val){
      this.pageNum = 1;
      this.stayTypeVal=val
      this.pageList();
    },
    dockTypeChange(val){
      this.pageNum = 1;
      this.dockTypeVal=val
      this.pageList();
    },
    // 文件上传失败
    handleError(err, file, fileList) {
      this.$message.error('文件上传失败')
    },
    // 根据会议id查询对接人列表
    async findDockingPersonList() {
      let data = _qs.stringify({
        conferenceId:window.sessionStorage.getItem('conferenceId')
      });
      let { data: res } = await this.$axios.dockingPersonList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.dockingOptions = res.data;
      }
    },
    async statusChange(row){
      let data = _qs.stringify({
        id:row.id,
        conferenceId:window.sessionStorage.getItem('conferenceId'),//会议id
        name: row.name,//参会人姓名
        phone:row.phone,//手机号
        workUnit:row.workUnit,//单位
        duties:row.duties,//职务
        idCard:row.idCard,//身份证号
        sex:row.sex,//性别
        email:row.email,//邮箱
        isConference:row.isConference,//是否确认参会 0 未参会 1已参会
        status:row.status,//短信发送状态 0 未发送 1已发送
        dockingPerson:row.dockingPerson,//对接人
        participateType:row.participateType,//参会人类型
        remark:row.remark//备注
      });
      let { data: res } = await this.$axios.updaAttenMans(data);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.pageList();
        this.$message({
          message: res.msg,
          type: "success",
        });
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    async confeChange(row){
        let data = _qs.stringify({
          id:row.id,
          conferenceId:window.sessionStorage.getItem('conferenceId'),//会议id
          name: row.name,//参会人姓名
          phone:row.phone,//手机号
          workUnit:row.workUnit,//单位
          duties:row.duties,//职务
          idCard:row.idCard,//身份证号
          sex:row.sex,//性别
          email:row.email,//邮箱
          isConference:row.isConference,//是否确认参会 0 未参会 1已参会
          status:row.status,//短信发送状态 0 未发送 1已发送
          dockingPerson:row.dockingPerson,//对接人
          participateType:row.participateType,//参会人类型
          remark:row.remark//备注
        });
        let { data: res } = await this.$axios.updaAttenMans(data);
        // console.log(res);
        if (res.code == 401) {
          this.$router.push("/login");
        } else if (res.code == 200) {
          this.pageList();
          this.$message({
            message: res.msg,
            type: "success",
          });
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
    },
    async pageList() {
      let data = _qs.stringify({
      page: this.pageNum, //页数
      size: this.pageSize, //每页显示条数
      name:this.attenName,//参会人姓名
      phone:this.attenPhone,//手机号
      status:this.attStaVal,//短信发送状态 0 未发送 1 已发送
      sendStatus:this.activityStaVal,// 活动短信发送状态
      activityStatus:this.activityConVal,//是否参加活动
      conferenceId:window.sessionStorage.getItem('conferenceId'),
      isConference:this.attConVal,//是否确认参会 0待确认 1确认
      participateType:this.attTypeVal, //参会人员类型 0参会人员 1普通嘉宾 2演讲嘉宾
      iCar:this.carTypeVal,
      iAccommodation:this.stayTypeVal,
      iDock:this.dockTypeVal
    });
      let { data: res } = await this.$axios.pageList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.tableData = res.data.pageList.records;
        this.total = res.data.pageList.total;
        if(res.data.haveAuth=='1'){
          this.haveAuth=false
        }else if(res.data.haveAuth=='0'){
          this.haveAuth=true
        }

        if(res.data.addAuth=='1'){
          this.addAuth=false
        }else if(res.data.addAuth=='0'){
          this.addAuth=true
        }

      }
    },

    // 科室管理分页
    handleCurrentChange(val) {
      this.pageNum = val;
      // console.log(`当前页: ${val}`);
      this.pageList();
    },
    // 点击添加科室
    depaddKeshi() {
      this.findDockingPersonList();
      this.AdddialogVisible = true;
    },
    // 点击添加科室的时候取消正则
    AdddialogVisibleClose() {
      this.addName = "添加参会人员";
      this.btnText = "添加";
      this.reayName = "";
      this.AdddialogVisible = false;
      this.$refs.AddDateRef.resetFields();
      this.AddDateModel = {
        name: '',
        phone:'',
        workUnit:'',
        duties:'',
        idCard:'',
        sex:'',
        email:'',
        isConference:'0',
        status:'0',
        participateType:'',
        dockingPerson:'',
        remark:''
      };
    },
    AddStayVisibleClose(){
      this.addName = "维护住宿信息";
      this.btnText = "提交";
      this.reayName = "";
      this.AddStayVisible = false;
      this.$refs.AddStayRef.resetFields();
      this.stayShow =false;
      this.addStayClick = true;
      this.addStayClickKing = false;
    },
    AddCarVisibleClose(){
      this.addName = "维护车辆信息";
      this.btnText = "提交";
      this.reayName = "";
      this.AddCarVisible = false;
      this.$refs.AddCarRef.resetFields();
      this.carShow=false;
      this.carNumShow =false;
      this.addCarClick = true;
      this.addCarClickKing = false;
    },
    AddDockVisibleClose(){
      this.addName = "维护对接人住宿信息";
      this.btnText = "提交";
      this.reayName = "";
      this.AddDockVisible = false;
      this.$refs.AddDockRef.resetFields();
      this.addDockClick = true;
      this.addDockClickKing = false;
    },
    parmen() {
      if (this.btnText == "添加") {
        this.addAtten();
      } else if (this.btnText == "修改") {
        this.editMeet();
      }
    },
    async addAttenMans(data) {
      let { data: res } = await this.$axios.addAttenMans(data);
      // console.log(res);
      this.addDepClick = true;
      this.addDepClickKing = false;
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.AdddialogVisibleClose();
        this.pageList();
        this.$message({
          message: res.msg,
          type: "success",
        });
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    async addStayMans(data) {
      let { data: res } = await this.$axios.addDetailMans(data);
      // console.log(res);
      this.addStayClick = true;
      this.addStayClickKing = false;
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.AddStayVisibleClose();
        this.AddStayModel = {
          iAccommodation: '',
          checkInTime:'',
          checkOutTime:''
        };
        this.pageList();
        this.$message({
          message: res.msg,
          type: "success",
        });
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    async addDockMans(data) {
      let { data: res } = await this.$axios.addDetailMans(data);
      // console.log(res);
      this.addDockClick = true;
      this.addDockClickKing = false;
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.AddDockVisibleClose();
        this.AddDockModel = {
          dockingPersonIn: '',
          dockingPersonOut:''
        };
        this.pageList();
        this.$message({
          message: res.msg,
          type: "success",
        });
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    async addCarMans(data) {
      let { data: res } = await this.$axios.addDetailMans(data);
      // console.log(res);
      this.addCarClick = true;
      this.addCarClickKing = false;
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.AddCarVisibleClose();
        this.AddCarModel = {
          iCar:'',
          incomingInformation:'',
          trafficDepartureTime:'',
          trafficArrivalTime:'',
          returnInformation:'',
          returnDepartureTime:'',
          returnArrivalTime:'',
          comeTicket:'',
          returnTicket:'',
          carNumber:''
        };
        this.pageList();
        this.$message({
          message: res.msg,
          type: "success",
        });
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    addStayParmen(){
      this.$refs.AddStayRef.validate((valid) => {
        if (valid) {
          this.addStayClick = false;
          this.addStayClickKing = true;

          // 如果是不需要去掉 入住时间和退房时间
          if(this.AddStayModel.iAccommodation == '0'){
            this.AddStayModel.checkInTime='';
            this.AddStayModel.checkOutTime='';
          }

          let data = _qs.stringify({
            id:this.detailId,
            participateUserId:this.perId,
            // 住宿
            iAccommodation:this.AddStayModel.iAccommodation,
            checkInTime:this.AddStayModel.checkInTime,
            checkOutTime:this.AddStayModel.checkOutTime,

            // 车辆
            iCar:this.AddCarModel.iCar,
            incomingInformation:this.AddCarModel.incomingInformation,
            trafficDepartureTime:this.AddCarModel.trafficDepartureTime,
            trafficArrivalTime:this.AddCarModel.trafficArrivalTime,
            returnInformation:this.AddCarModel.returnInformation,
            returnDepartureTime:this.AddCarModel.returnDepartureTime,
            returnArrivalTime:this.AddCarModel.returnArrivalTime,
            comeTicket:this.AddCarModel.comeTicket,
            returnTicket:this.AddCarModel.returnTicket,
            carNumber:this.AddCarModel.carNumber,

            // 对接人
            dockingPersonIn:this.AddDockModel.dockingPersonIn,
            dockingPersonOut:this.AddDockModel.dockingPersonOut
          });
          this.addStayMans(data);
        }
      });
    },
    addDockParmen(){
      this.addDockClick = false;
      this.addDockClickKing = true;

      let data = _qs.stringify({
        id:this.detailId,
        participateUserId:this.perId,
        // 住宿
        iAccommodation:this.AddStayModel.iAccommodation,
        checkInTime:this.AddStayModel.checkInTime,
        checkOutTime:this.AddStayModel.checkOutTime,

        // 车辆
        iCar:this.AddCarModel.iCar,
        incomingInformation:this.AddCarModel.incomingInformation,
        trafficDepartureTime:this.AddCarModel.trafficDepartureTime,
        trafficArrivalTime:this.AddCarModel.trafficArrivalTime,
        returnInformation:this.AddCarModel.returnInformation,
        returnDepartureTime:this.AddCarModel.returnDepartureTime,
        returnArrivalTime:this.AddCarModel.returnArrivalTime,
        comeTicket:this.AddCarModel.comeTicket,
        returnTicket:this.AddCarModel.returnTicket,
        carNumber:this.AddCarModel.carNumber,

        // 对接人
        dockingPersonIn:this.AddDockModel.dockingPersonIn,
        dockingPersonOut:this.AddDockModel.dockingPersonOut
      });
      this.addDockMans(data);
    },
    addCarParmen(){
      this.$refs.AddCarRef.validate((valid) => {
        if (valid) {
          this.addCarClick = false;
          this.addCarClickKing = true;


          // 如果是不需要去掉 去掉全部
          if(this.AddCarModel.iCar == '0'){
            this.AddCarModel.incomingInformation='';
            this.AddCarModel.trafficArrivalTime='';
            this.AddCarModel.trafficDepartureTime='';
            this.AddCarModel.returnInformation='';
            this.AddCarModel.returnArrivalTime='';
            this.AddCarModel.returnDepartureTime='';
            this.AddCarModel.comeTicket='';
            this.AddCarModel.returnTicket='';
            this.AddCarModel.carNumber='';
          }else if(this.AddCarModel.iCar == '1' || this.AddCarModel.iCar == '2'){
            this.AddCarModel.carNumber='';
          }else{
            this.AddCarModel.incomingInformation='';
            this.AddCarModel.trafficArrivalTime = null;
            this.AddCarModel.trafficDepartureTime = null;
            this.AddCarModel.returnInformation='';
            this.AddCarModel.returnArrivalTime = null;
            this.AddCarModel.returnDepartureTime = null;
            this.AddCarModel.comeTicket='';
            this.AddCarModel.returnTicket='';
          }

          let data = _qs.stringify({
            id:this.detailId,
            participateUserId:this.perId,
            // 住宿
            iAccommodation:this.AddStayModel.iAccommodation,
            checkInTime:this.AddStayModel.checkInTime,
            checkOutTime:this.AddStayModel.checkOutTime,

            // 车辆
            iCar:this.AddCarModel.iCar,
            incomingInformation:this.AddCarModel.incomingInformation,
            trafficDepartureTime:this.AddCarModel.trafficDepartureTime,
            trafficArrivalTime:this.AddCarModel.trafficArrivalTime,
            returnInformation:this.AddCarModel.returnInformation,
            returnDepartureTime:this.AddCarModel.returnDepartureTime,
            returnArrivalTime:this.AddCarModel.returnArrivalTime,
            comeTicket:this.AddCarModel.comeTicket,
            returnTicket:this.AddCarModel.returnTicket,
            carNumber:this.AddCarModel.carNumber,

            // 对接人
            dockingPersonIn:this.AddDockModel.dockingPersonIn,
            dockingPersonOut:this.AddDockModel.dockingPersonOut
          });
          this.addCarMans(data);
        }
      });
    },
    addAtten() {
      this.$refs.AddDateRef.validate((valid) => {
        if (valid) {
          this.addDepClick = false;
          this.addDepClickKing = true;
          let data = _qs.stringify({
            conferenceId:window.sessionStorage.getItem('conferenceId'),//会议id
            name: this.AddDateModel.name,//参会人姓名
            phone:this.AddDateModel.phone,//手机号
            workUnit:this.AddDateModel.workUnit,//单位
            duties:this.AddDateModel.duties,//职务
            idCard:this.AddDateModel.idCard,//身份证号
            sex:this.AddDateModel.sex,//性别
            email:this.AddDateModel.email,//邮箱
            isConference:this.AddDateModel.isConference,//是否确认参会 0 未参会 1已参会
            status:this.AddDateModel.status,//联系进度 0 未联系 1已联系
            dockingPerson:this.AddDateModel.dockingPerson,//对接人
            participateType:this.AddDateModel.participateType,//参会人类型
            remark:this.AddDateModel.remark//备注
          });
          this.addAttenMans(data);
        }
      });
    },
    editStay(row){
      this.editDetail(row);
      this.AddStayVisible = true;
    },
    editDock(row){
      this.editDetail(row);
      this.AddDockVisible = true;
    },
    editCar(row){
      this.editDetail(row);
      this.AddCarVisible = true;
    },
    async editDetail(row){
      this.perId = row.id;

      let data = _qs.stringify({
        participateUserId:row.id
      });
      let { data: res } = await this.$axios.findAttenDetail(data);
      // console.log(res)
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        if(res.data){
          this.detailId = res.data.id;

          this.AddStayModel = {
            iAccommodation: res.data.iAccommodation,
            checkInTime:res.data.checkInTime,
            checkOutTime:res.data.checkOutTime
          };

          this.AddCarModel = {
            iCar:res.data.iCar,
            incomingInformation:res.data.incomingInformation,
            trafficDepartureTime:res.data.trafficDepartureTime,
            trafficArrivalTime:res.data.trafficArrivalTime,
            returnInformation:res.data.returnInformation,
            returnDepartureTime:res.data.returnDepartureTime,
            returnArrivalTime:res.data.returnArrivalTime,
            comeTicket:res.data.comeTicket,
            returnTicket:res.data.returnTicket,
            carNumber:res.data.carNumber
          };

          this.AddDockModel = {
            dockingPersonIn: res.data.dockingPersonIn,
            dockingPersonOut:res.data.dockingPersonOut
          };

          if(res.data.iAccommodation == '1'){
            this.stayShow = true;
          }else {
            this.stayShow = false;
          }

          if(!res.data.iCar || res.data.iCar=='0'){
            this.carShow=false;
            this.carNumShow = false;
          }
          else if (res.data.iCar == '3') {
            this.carNumShow = true;
            this.carShow=false;
          }
          else{
            this.carShow=true;
            this.carNumShow = false;
          }
        }
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    // 点击操作删除
    async delManMeet(row) {
      const confirmResult = await this.$confirm("确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);

      if (confirmResult !== "confirm") {
        return this.$message.info("已经取消删除");
      }
      let data = _qs.stringify({
        id: row.id,
      });
      let { data: res } = await this.$axios.deleAttenMans(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.pageList();
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    editManaMeet(row) {
      this.findDockingPersonList();
      // console.log(row)
      this.addName = "修改参会人员";
      this.btnText = "修改";
      this.perId = row.id;

      this.AddDateModel = JSON.parse(JSON.stringify(row));
      this.AddDateModel.sex=row.sex+''

      this.AddDateModel.status=row.status+''
      this.AddDateModel.isConference=row.isConference+''
      this.AdddialogVisible = true;
    },
    // 点击修改科室用户管理的添加
    editMeet() {
      this.$refs.AddDateRef.validate(async (valid) => {
        if (!valid) return;
        this.addDepClick = false;
        this.addDepClickKing = true;
        let data = _qs.stringify({
          id:this.perId,
          conferenceId:window.sessionStorage.getItem('conferenceId'),//会议id
          name: this.AddDateModel.name,//参会人姓名
          phone:this.AddDateModel.phone,//手机号
          workUnit:this.AddDateModel.workUnit,//单位
          duties:this.AddDateModel.duties,//职务
          idCard:this.AddDateModel.idCard,//身份证号
          sex:this.AddDateModel.sex,//性别
          email:this.AddDateModel.email,//邮箱
          isConference:this.AddDateModel.isConference,//是否确认参会 0 未参会 1已参会
          status:this.AddDateModel.status,//联系进度 0 未联系 1已联系
          dockingPerson:this.AddDateModel.dockingPerson,//对接人
          participateType:this.AddDateModel.participateType,//参会人类型
          remark:this.AddDateModel.remark//备注
        });
        let { data: res } = await this.$axios.updaAttenMans(data);
        // console.log(res);
        this.addDepClick = true;
        this.addDepClickKing = false;
        if (res.code == 401) {
          this.$router.push("/login");
        } else if (res.code == 200) {
          this.AdddialogVisibleClose();
          this.pageList();
          this.$message({
            message: res.msg,
            type: "success",
          });
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    async exportAttenDetail(){
      if(!this.multipleSelection || this.multipleSelection.length === 0){
        return this.$message({
          message: '请勾选要导出的参会人！',
          type: "warning",
        });
      }

      this.exportCilck = false;
      this.exportCilckTwo = true;
      let data = _qs.stringify({
        ids:this.multipleSelection.join(',')
      });

      //跳转并携带对象参数
      await this.$axios.exportAttenDetail(data,'参会人员信息.xlsx');

      this.exportCilck = true;
      this.exportCilckTwo = false;
    },
    async sendMessage(){
      // 判断是否勾选了
      if(!this.multipleSelection || this.multipleSelection.length === 0){
        return this.$message({
          message: '请勾选要发送的参会人！',
          type: "warning",
        });
      }

      // 判断是否有已发送的
      if(this.messageStatusList.indexOf('1') !==-1 ){
        const confirmResult = await this.$confirm("本次勾选的人员中包含已发送过短信的！！！是否继续发送", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).catch((err) => err);

        if (confirmResult !== "confirm") {
          return this.$message.info("已经取消发送");
        }
      }

      const confirmResult = await this.$confirm("会议邀请中包含会议基本信息及会议议程信息，请确认以上信息维护完成后再发送！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);

      if (confirmResult !== "confirm") {
        return this.$message.info("已经取消发送");
      }

      this.sendMessageCilck = false;
      this.sendMessageCilckTwo = true;

      // 发送短信接口
      let data = _qs.stringify({
        conferenceId:window.sessionStorage.getItem('conferenceId'),
        ids:this.multipleSelection.join(',')
      });

      let res = await this.$axios.sendInviteSMS(data);
      if (res.data.code == 401) {
        this.$router.push("/login");
      } else if (res.data.code == 200) {
        this.pageList();
        this.$refs.multipleTable.clearSelection()
        this.$message({
          message: res.data.msg,
          type: "success",
        });
      } else {
        this.$message({
          message: res.data.msg,
          type: "error",
        });
      }
      this.sendMessageCilck = true;
      this.sendMessageCilckTwo = false;
    },
    // 发送活动邀请
    async sendActivity(){
      // 判断是否勾选了
      if(!this.multipleSelection || this.multipleSelection.length === 0){
        return this.$message({
          message: '请勾选要发送的参会人！',
          type: "warning",
        });
      }

      // 判断是否有已发送的
      if(this.sendStatusList.indexOf('1') !==-1 ){
        const confirmResult = await this.$confirm("本次勾选的人员中包含已发送过短信的！！！是否继续发送", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).catch((err) => err);

        if (confirmResult !== "confirm") {
          return this.$message.info("已经取消发送");
        }
      }

      const confirmResult = await this.$confirm("是否确认发送？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);

      if (confirmResult !== "confirm") {
        return this.$message.info("已经取消发送");
      }

      this.sendActivityCilck = false;
      this.sendActivityCilckTwo = true;

      // 发送短信接口
      let data = _qs.stringify({
        conferenceId:window.sessionStorage.getItem('conferenceId'),
        ids:this.multipleSelection.join(',')
      });

      let res = await this.$axios.sendActivitySMS(data);
      if (res.data.code == 401) {
        this.$router.push("/login");
      } else if (res.data.code == 200) {
        this.pageList();
        this.$refs.multipleTable.clearSelection()
        this.$message({
          message: res.data.msg,
          type: "success",
        });
      } else {
        this.$message({
          message: res.data.msg,
          type: "error",
        });
      }
      this.sendActivityCilck = true;
      this.sendActivityCilckTwo = false;
    },
    // 发送新闻快讯
    async sendNews(){
      // 判断是否勾选了
      if(!this.multipleSelection || this.multipleSelection.length === 0){
        return this.$message({
          message: '请勾选要发送的参会人！',
          type: "warning",
        });
      }

      const confirmResult = await this.$confirm("是否确认发送？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);

      if (confirmResult !== "confirm") {
        return this.$message.info("已经取消发送");
      }

      this.sendNewsCilck = false;
      this.sendNewsCilckTwo = true;

      // 发送短信接口
      let data = _qs.stringify({
        conferenceId:window.sessionStorage.getItem('conferenceId'),
        ids:this.multipleSelection.join(',')
      });

      let res = await this.$axios.sendNewsSMS(data);
      if (res.data.code == 401) {
        this.$router.push("/login");
      } else if (res.data.code == 200) {
        this.pageList();
        this.$refs.multipleTable.clearSelection()
        this.$message({
          message: res.data.msg,
          type: "success",
        });
      } else {
        this.$message({
          message: res.data.msg,
          type: "error",
        });
      }
      this.sendNewsCilck = true;
      this.sendNewsCilckTwo = false;
    },
    async exportTemplate(){
      this.exportTemplateCilck = false;
      this.exportTemplateCilckTwo = true;
      // 导出参会人模板接口
      //跳转并携带对象参数
      await this.$axios.exportTemplate(null,'参会人员信息模板.xlsx');
      this.exportTemplateCilck = true;
      this.exportTemplateCilckTwo = false;
    },
    // 文件状态改变
    async handleChange(file, fileList) {
      if (file) {
        this.file = fileList.slice(-3)

        // 可以根据后台接口要求来决定参数的类型
        this.formdata = new FormData()
        this.formdata.append('file', file.raw)
        this.formdata.append('conferenceId', window.sessionStorage.getItem('conferenceId'))

        let res = await this.$axios.importParticipateUser(this.formdata);
        // console.log(res)
        if (res.data.code == 401) {
          this.$router.push("/login");
        } else if (res.data.code == 200) {
          // console.log(res.data.data)
          this.$message({
            message: '导入成功',
            type: "success",
          });
          this.$refs.upload.clearFiles(); //上传成功之后清除历史记录
          this.pageList();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
          this.$refs.upload.clearFiles(); //上传成功之后清除历史记录
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
  text-align: inherit;
  .btnCen {
    button {
      width: 150px;
    }
  }
}
.el-pagination{
  margin-top: 20px;
  text-align: center;
}

/deep/ .el-input__inner{
  width: 220px;
}

.RightDiv{
  float: right;
  margin-bottom: 20px;
  display: flex;
  .firDiv{
    margin-left: 10px;
  }
  .firDivF{
    margin-left: 10px;
    margin-right: 169px;
  }
}

.depTitle {
  display: flex;
  margin: 0 auto;
  margin-bottom: 20px;
}

/deep/ .el-button.el-button--primary.is-disabled{
  color: #fff!important;
  background-color: #a0cfff!important;
  border-color: #a0cfff!important;
}
</style>
